import React from 'react';
import GmapImage from './gmap_contact_form.png';

export default function Contact({ isShowing, onBottomRightButtonClick, onContactClose }) {
    return (
        <div className={`contact-popup ${isShowing ? 'active' : ''}`}>
            <div className="contact-popup-inner">
            <div
                className="navigation-dropdown-close"
                onClick={onContactClose}
            >
                CLOSE
            </div>
                <h2>Let us meet</h2>
                <div class="cnt-right">
                    <a href="mailto:request@thegambit.gr">request@thegambit.gr</a>
                </div>
                <div class="cnt-right">
                    <a href="tel:00302103388964">210 3388964</a>
                </div>
                <div class="cnt-right">
                    <a href="https://www.google.com/maps/place/%CE%94%CE%B7%CE%BC%CE%BF%CE%BA%CF%81%CE%AF%CF%84%CE%BF%CF%85+1,+%CE%91%CE%B8%CE%AE%CE%BD%CE%B1+106+71/@37.9788972,23.7341572,17.58z/data=!4m10!1m2!2m1!1zzpTOt868zr_Ous-Bzq_PhM6_z4UgMSwgMTA2NzEsIDPOv8-CIM-Mz4HOv8-Gzr_Pgg!3m6!1s0x14a1bd38e520731b:0x95ee284219dd7adf!8m2!3d37.9785539!4d23.7370719!15sCjHOlM63zrzOv866z4HOr8-Ezr_PhSAxLCAxMDY3MSwgM86_z4Igz4zPgc6_z4bOv8-CkgEQZ2VvY29kZWRfYWRkcmVzc-ABAA!16s%2Fg%2F11csn2rf36?entry=ttu" target="_blank">Dimokritou 1, Athens, 106 71</a>
                </div>
                <div class="cnt-right">
                    <a>Monday - Thursday | 10:00 - 18:00</a>
                </div>
                <div className="contact-popup-social">
                    <a href="https://www.instagram.com/the_gambit_gr/" target="_blank" rel="noopener">INSTAGRAM</a>
                     {/*<a href="#twitter">TWITTER</a> */}
                    <a href="https://gr.linkedin.com/company/the-gambit-gr" target="_blank" rel="noopener">LINKEDIN</a>
                </div>
                <div className='straight-white-line'></div>
                <a href="https://www.google.com/maps/place/%CE%94%CE%B7%CE%BC%CE%BF%CE%BA%CF%81%CE%AF%CF%84%CE%BF%CF%85+1,+%CE%91%CE%B8%CE%AE%CE%BD%CE%B1+106+71/@37.9788972,23.7341572,17.58z/data=!4m10!1m2!2m1!1zzpTOt868zr_Ous-Bzq_PhM6_z4UgMSwgMTA2NzEsIDPOv8-CIM-Mz4HOv8-Gzr_Pgg!3m6!1s0x14a1bd38e520731b:0x95ee284219dd7adf!8m2!3d37.9785539!4d23.7370719!15sCjHOlM63zrzOv866z4HOr8-Ezr_PhSAxLCAxMDY3MSwgM86_z4Igz4zPgc6_z4bOv8-CkgEQZ2VvY29kZWRfYWRkcmVzc-ABAA!16s%2Fg%2F11csn2rf36?entry=ttu" target="_blank">
                    <img className='gmap-image-contact-form' src={GmapImage} />
                </a>
            </div>
            <div className="bottom-right-button" onClick={onBottomRightButtonClick}>
                <span>SELECT</span>
                <span>A TOPIC</span>
            </div>
        </div>
    );
}